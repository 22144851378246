import CheckPopupBase from "./index";
// import Dialog from "../../molecules/Dialog";
import { useUser } from "@clerk/clerk-react";
import { differenceInMinutes, format } from "date-fns";
import { BadgeAlert, BadgeCheck } from "lucide-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ICheckInputSingleLineViewModel } from "../../../application/viewModels/CheckInputSingleLineViewModel";
import { Button } from "../../ui/button";
import CheckStateCard, {
  ActionContainer,
  AnswerBadge,
  AnswerContainer,
  PrimaryActionButton,
  SecondaryActionButton,
} from "./CheckStateCard";

interface ICheckPopupInputSingleLineProps {
  check: ICheckInputSingleLineViewModel;
  onFinish: () => void;
  title: string;
  signed: boolean;
  sectionTitle: string;
}

export const CheckPopupInputSingleLinePrint = observer(
  ({
    check,
    onFinish,
    title,
    signed,
    sectionTitle,
  }: ICheckPopupInputSingleLineProps): JSX.Element => {
    // const [dialogOpen, setDialogOpen] = useState(false);
    // const [value, setValue] = useState<string | undefined>(undefined);

    // const handleSubmit = async (value: string) => {
    //   await check.store(value);

    //   setDialogOpen(false);

    //   if (check.finished) {
    //     onFinish();
    //   }
    // };

    const handleReset = () => {
      check.reset();
    };
    const { t } = useTranslation();
    const { user } = useUser();

    async function printLabel() {
      //TODO: print using Zebra printer...
    }

    async function handlePrint(approved: boolean) {
      await printLabel();

      //TODO: update check text and append new entry after printing the label succesfully.
      const entry = `${approved ? "approved;" : "not-approved;"} ${
        user?.fullName
      }; ${format(new Date(), "MM-dd-yyyy HH:mm:ss")}`;

      const previousEntries = check.text;

      await check.store(
        `${previousEntries ? previousEntries + "|" : ""}${entry}`,
      );
    }

    return (
      <CheckPopupBase
        activities={check.activities}
        instructionId={check.instructionId}
        signed={signed}
        check={check}
      >
        <CheckStateCard
          signed={signed}
          check={check}
          sectionTitle={sectionTitle}
        >
          {check.text !== null && (
            <AnswerContainer>
              <AnswerBadge
                lastTouchedBy={check.lastTouchedBy}
                lastTouchedAt={check.lastTouchedAt}
                checklistAccepted={signed}
                onReset={handleReset}
                canDelete={false}
              >
                {/* <TextCursorInput className="w-5 h-5 mr-2" /> */}
                <div className="flex flex-col gap-y-1 py-2.5 w-full">
                  <div className="divide-y divide-border/30 divide-dashed">
                    {check.text.split("|").map((text, idx) => {
                      const previousDate =
                        idx > 0 && check && check.text
                          ? new Date(
                              check.text?.split("|")[idx - 1].split(";")[2],
                            )
                          : undefined;
                      const currentDate = new Date(text.split(";")[2]);
                      const deltaTimeInMinutes = previousDate
                        ? differenceInMinutes(currentDate, previousDate)
                        : undefined;

                      return (
                        <div key={text} className="flex justify-between py-1.5">
                          <div className="flex items-center">
                            <div>
                              {text.split(";")[0] === "approved" ? (
                                <div className="bg-green-600 mr-1.5 h-5 w-5 flex items-center justify-center rounded-full">
                                  <BadgeCheck className="w-4 h-4 text-white" />
                                </div>
                              ) : (
                                <div className="bg-red-600 mr-1.5 h-5 w-5 flex items-center justify-center rounded-full">
                                  <BadgeAlert className="w-4 h-4 text-white" />
                                </div>
                              )}
                            </div>
                            <p className="text-sm truncate">
                              #{idx + 1} {text.split(";")[1]}
                            </p>
                          </div>

                          <div className="flex items-center">
                            <div className="flex items-center font-mono">
                              <p className="text-xs font-medium uppercase mr-2 text-black/30 whitespace-nowrap">
                                {format(currentDate, "dd MMM yy")}
                              </p>
                              <p className="text-xs font-medium whitespace-nowrap mr-2">
                                {format(currentDate, "HH:mm:ss")}
                              </p>
                              <p className="text-xs font-medium whitespace-nowrap">
                                +
                                {deltaTimeInMinutes === undefined
                                  ? 0
                                  : deltaTimeInMinutes}{" "}
                                min
                              </p>
                            </div>
                            {/* <div className="ml-2">
                              <X className="cursor-pointer hover:text-black w-4 h-4 text-muted-foreground" />
                            </div> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex items-center">
                    <Button
                      onClick={() => handlePrint(true)}
                      size={"sm"}
                      variant={"outline"}
                      className="ml-auto w-full mt-2 rounded-l-full border-2"
                    >
                      Goedkeuren
                    </Button>
                    <Button
                      onClick={() => handlePrint(false)}
                      size={"sm"}
                      variant={"outline"}
                      className="ml-auto w-full mt-2 rounded-r-full border-r-2 border-y-2 border-l-0"
                    >
                      Afkeuren
                    </Button>
                  </div>
                </div>
              </AnswerBadge>
            </AnswerContainer>
          )}

          {!signed && !check.text && (
            <ActionContainer>
              <PrimaryActionButton
                onClick={() => handlePrint(true)}
                disabled={check.loading}
              >
                {t("inspectionPage.checkPopup.okNokCheck.acceptButton")}
              </PrimaryActionButton>
              <SecondaryActionButton
                onClick={() => handlePrint(false)}
                disabled={check.loading}
              >
                {t("inspectionPage.checkPopup.okNokCheck.rejectButton")}
              </SecondaryActionButton>
            </ActionContainer>
          )}
        </CheckStateCard>
      </CheckPopupBase>
    );
  },
);
